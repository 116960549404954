var $ = document.querySelector.bind(document);

var langSelector = '.js-change-lang-select';
var openOrderCarSelector = '.js-open-order-car';

var orderCarContainer;

/**
 * Build url for passed language
 * @param {String} [lang=] language
 * @return {String} url
 */
function buildUrlForLang(lang) {
  var pathComponents = window.location.pathname.split('/');
  if (pathComponents[1] && pathComponents[1].length === 2) {
    pathComponents.splice(1, 1);
  }
  if (lang) {
    pathComponents.splice(1, 0, lang);
  }
  return window.location.origin + pathComponents.join('/');
}

/**
 * Handler which fired when lang selector value is changed
 * @this {HTMLSelectElement}
 */
var onLangSelectChange = function onLangSelectChange() {
  window.location = buildUrlForLang(this.value);
};

var onOpenOrderCarClicked = function onOpenOrderCarClicked(e) {
  var button = e.currentTarget;
  button.blur();

  orderCarContainer = document.createElement('div');
  orderCarContainer.setAttribute('class', 'c-order-car-frame');

  var isMobile = window.innerWidth < 500;
  var orderCarFrame = document.createElement('iframe');
  orderCarFrame.setAttribute(
    'src',
    window.config.frameUrl + '/dashboard?lang=' + window.locale + '&showClose=' + (isMobile ? 'true' : 'false')
  );
  orderCarFrame.setAttribute('class', 'c-order-car-content');
  orderCarContainer.appendChild(orderCarFrame);

  document.body.appendChild(orderCarContainer);
  document.body.offsetHeight;
  orderCarContainer.classList.add('c-order-car-frame--active');
};

var closeFrame = function closeFrame() {
  orderCarContainer.classList.remove('c-order-car-frame--active');
  setTimeout(function timeout() {
    orderCarContainer.parentElement.removeChild(orderCarContainer);
  }, 500);
};

var onBodyClick = function onBodyClick(e) {
  var isContainer = e.target.classList.contains('c-order-car-frame');
  if (isContainer && orderCarContainer.parentElement) {
    closeFrame();
  }
};

var handleWindowMessage = function handleWindowMessage(e) {
  if (e.origin !== window.config.frameUrl) {
    return;
  }
  if (e.data === 'closeFrame') {
    closeFrame();
  }
};

document.addEventListener('DOMContentLoaded', function onDomReady() {
  $(langSelector).addEventListener('change', onLangSelectChange);
  if ($(openOrderCarSelector)) {
    $(openOrderCarSelector).addEventListener('click', onOpenOrderCarClicked);
  }
  document.body.addEventListener('click', onBodyClick);
  window.addEventListener('message', handleWindowMessage);
});
